const DocumentacionSubida = () => import('@/app/views/landings/views/DocumentacionSubida');
const Registrado = () => import('@/app/views/landings/views/Registrado');


const landingsRoutes = [
  {
    path: '/documentacion-subida',
    name: 'Documentación Subida',
    component: DocumentacionSubida,
    meta: {
      auth: false
    }
  },
  {
    path: '/registrado',
    name: 'Registrado',
    component: Registrado,
    meta: {
      auth: false
    }
  }
];

export default landingsRoutes;
