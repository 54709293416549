export const BASE_URL = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`; // domain/api
export const ADMIN_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_ADMIN}`; // domain/api/admin
export const CLIENT_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_CLIENT}`; // domain/api/admin


export const ESTADOS_CLIENTES = [
  { value: 1, label : 'Lead'},
  { value: 2, label : 'Pendiente documentación'},
  { value: 3, label : 'Pendiente validación'},
  { value: 4, label : 'Activo' },
  { value: 5, label : 'Cancelado'}
];

export const ESTADOS_PEDIDOS = [
  { value: 14, label: 'En espera' },
  { value: 6, label: 'Recibido' },
  { value: 7, label: 'Producción' },
  { value: 8, label: 'Pendiente material' },
  { value: 9, label: 'Pendiente información' },
  { value: 15, label: 'Pendiente formulación' },
  { value: 10, label: 'Finalizado y en proceso de envio' },
  { value: 11, label: 'Enviado' },
  { value: 12, label: 'Cancelado' },
  { value: 13, label: 'Atrasado' },
];


export const METODOS_ENVIO = [
  { value: 'Bicicleta', label : 'Bicicleta'},
  { value: 'MRW', label : 'MRW'},
  { value: 'Hefame', label : 'Hefame'},
  { value: 'Cofares', label : 'Cofares'},
  { value: 'Recoge en farmacia', label : 'Recoge en farmacia'},
];


export const CALENDAR_LANG = {
  formatLocale: {
    firstDayOfWeek: 1,
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    placeholder: {
      date: 'Selecciona una Fecha',
      dateRange: 'Selecciona un Rango de Fechas'
    }
  },
}

export const QUILL_CONFIG_COMENTARIO = {  // QUILL_CONFIG_SNOW
  theme: 'snow',
  placeholder: 'Escribe un comentario ...',
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      // ['blockquote', 'code-block'],  //
      /*[{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],  //
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      // [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  //
      [{ 'font': [] }],
      // [{ 'color': [] }, { 'background': [] }],  //
      [{ 'align': [] }],
      ['clean'],
      // ['link', 'image', 'video']  //*/
    ],
    syntax: {
      // eslint-disable-next-line no-undef
      highlight: text => hljs.highlightAuto(text).value
    }
  }
}
