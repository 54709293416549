const Login = () => import("@/app/views/auth/login/Login");
const RecoveryPassword = () => import("@/app/views/auth/recovery-password/RecoveryPassword");
const ForgotPassword = () => import("@/app/views/auth/forgot-password/ForgotPassword");
const Registrar = () => import("@/app/views/auth/registrar/Registrar");


const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: "/restablecer-contrasenya/:token",
    name: "recovery-password",
    component: RecoveryPassword,
    meta: {
      auth: false
    }
  },
  {
    path: "/restablecer-contrasenya",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      auth: false
    }
  },
  {
    path: "/registrar/:token",
    name: "registrar",
    component: Registrar,
    meta: {
      auth: false
    }
  }
];

export default authRoutes;
