const { VUE_APP_API_DOMAIN, VUE_APP_API_PATH, VUE_APP_API_BASE, VUE_APP_API_ADMIN, VUE_APP_API_CLIENT, NODE_ENV= '' } = process.env;

const environment = NODE_ENV.toLowerCase();
const apiBaseUrl = VUE_APP_API_DOMAIN.concat(VUE_APP_API_PATH);
const apiAdminUrl = VUE_APP_API_DOMAIN.concat(VUE_APP_API_PATH).concat(VUE_APP_API_ADMIN);
const basePrefix = VUE_APP_API_BASE;
const adminPrefix = VUE_APP_API_ADMIN;
const clientPrefix = VUE_APP_API_CLIENT;
const apiDomain = VUE_APP_API_DOMAIN;

export {
  environment,
  apiBaseUrl,
  apiAdminUrl,
  basePrefix,
  adminPrefix,
  clientPrefix,
  apiDomain
}

