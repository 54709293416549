import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'bootstrap';
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import App from './App'
import router from '@/app/router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import moment from 'moment';
import 'vue-cute-timeline/dist/index.css';

//components
import Toast from "@/app/shared/components/Toast";
import DeleteModal from "@/app/shared/components/modals/DeleteModal";
import SpinnerTtt from '@/app/shared/components/SpinnerTtt';
import PreguntarCambiosModal from "@/app/shared/components/modals/PreguntarCambiosModal";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueQuillEditor from 'vue-quill-editor';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Multiselect from 'vue-multiselect'
import { CDateRangePicker } from '@coreui/vue-pro'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.component('toast', Toast);
Vue.component('DeleteModal', DeleteModal);
Vue.component('SpinnerTtt', SpinnerTtt);
Vue.component('PreguntarCambiosModal', PreguntarCambiosModal);
Vue.component('date-picker', DatePicker);
Vue.component('suggest', VueBootstrapTypeahead);
Vue.component('multiselect', Multiselect);
Vue.component('CDateRangePicker', CDateRangePicker);

Vue.use(VueQuillEditor);
Vue.use(CoreuiVuePro, {key: '27A850AE-0B174928-8236C639-B73B45AE'})
Vue.prototype.$log = console.log.bind(console)

moment.locale('es');
window.moment = moment;

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  }
})
