const DetalleCliente = () => import('@/app/views/clientes/views/DetalleCliente');

const clientesRoutes = [
  {
    path: 'clientes',
    redirect: '/gestion/clientes',
    name: '',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '/clientes',
        name: 'Yo',
        component: DetalleCliente,
        meta: {
          auth: true,
        },
        children: [
          {
            path: '/gestion/clientes/yo',
            name: '',
            meta: {
              auth: true,
            }
          }
        ]
      },

    ]
  }
];

export default clientesRoutes;
