const SubirDocumentacion = () => import('@/app/views/configuracion/documentacion/views/SubirDocumentacion');


const documentacionRoutes = [
  {
    path: 'documentacion',
    redirect: '/configuracion/documentacion',
    name: 'Documentación',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: '',
        component: SubirDocumentacion,
        meta: {
          auth: true,
        }
      },
    ]
  }
];

export default documentacionRoutes;
